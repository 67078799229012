<div class="global-dialog ow-dialog window-a primary no-transparent">
  <loading></loading>

  <!-- TOP BAR BASE -->
  <div class="top-bar base">
    <div class="title">
      Sprzedaż Centrali
    </div>
  </div>

  <!-- EXIT BUTTON -->
  <m-ui-close-button></m-ui-close-button>

  <ng-container *ngIf="!isLoading && sales.length > 0; else noItemTpl">
    <div class="table-thead">
      <table class="sale">
        <tbody>
        <tr>
          <td class="icon w-38"></td>
          <td class="name w-70"></td>
          <td class="w-38">W magazynie</td>
          <td class="w-50">Zapas na dni</td>
          <td class="w-50">Sprzedaż z wczoraj</td>
          <td class="w-50">Cena</td>
          <td class="w-50 border-white">Przychód z wczoraj</td>
          <td class="sell w-57">Cena wyprzedaży <span class="absolute">Wyprzedaż</span></td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="sales loading-opacity">
      <ow-perfect-scrollbar [scrollGradient]="sales">
        <table class="sale">
          <tbody>
          <tr *ngFor="let sale of sales; let i = index">
            <td class="icon w-38">
              <img [src]="sale.product.icon + '.png' | asset: 'products/basic'">
            </td>
            <td class="name w-70">
              {{ sale.product.name }}
            </td>
            <td class="w-38">
              {{ sale.product_balance }}
            </td>
            <td class="w-50" [class.red-txt]="sale.days_reserve < 1">
              {{ sale.days_reserve_format }}
            </td>
            <td class="w-50">
              <div class="display-center">
                {{ sale.last_day_sold }}
              </div>
            </td>
            <td class="w-50">
              <div class="display-center">
                <m-ui-currency
                  [item]="{currency_id: CURRENCY_SALE_ID, amount: sale.warehouse_price}"
                  [stockView]="STOCK_VIEW.J"
                  [lack]="false"
                >
                </m-ui-currency>
              </div>
            </td>
            <td class="w-50 border-white">
              <div class="display-center">
                <m-ui-currency
                  [item]="{currency_id: CURRENCY_SALE_ID, amount: sale.last_day_income}"
                  [stockView]="STOCK_VIEW.J"
                  [lack]="false"
                >
                </m-ui-currency>
              </div>
            </td>
            <td class="sell w-57">
              <button class="base primary" (click)="openWarehouse(sale.product.product_id)">
                <m-ui-currency
                  [item]="{currency_id: CURRENCY_SALE_ID, amount: sale.sale_price}"
                  [stockView]="STOCK_VIEW.J"
                  [lack]="false"
                >
                </m-ui-currency>
              </button>

              <div *ngIf="i !== (sales.length - 1)" class="border-bg display-center">
                <div class="border"></div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </ow-perfect-scrollbar>
    </div>

    <div class="sum">
      Suma:
      <span>
        <m-ui-currency
          [item]="total"
          [stockView]="STOCK_VIEW.J"
          [lack]="false"
        >
        </m-ui-currency>
      </span>
    </div>
  </ng-container>

  <ng-template #noItemTpl>
    <div *ngIf="!isLoading" class="no-item">
      Brak wyprzedaży
      <br>
      Sprzedaż przez Centralę dostępna będzie po zaliczeniu gwiazdki 0.4
    </div>
  </ng-template>
</div>
