import { MenuButtonConfig } from "../interfaces/menu-buttonts-config.interface";

export const CUSTOM_MENU_BUTTONS_CONFIG: MenuButtonConfig[] = [
  // {
  //   type: "group",
  //   place: "menu-left",
  //   order: 1,
  //   name: "go-to",
  //   gui_notification_type: null,
  //   gui_unlocks_button: null,
  //   click_event: null,
  //   tooltip: "Idź do",
  //   icon: "location.png",
  //   show_only_active_me: true,
  //   group_name: null,
  //   group_variant: 3,
  //   onlyIsActiveMe: true,
  // },
  {
    type: "button",
    place: "menu-left",
    order: 2,
    name: "missions",
    gui_notification_type: null,
    gui_unlocks_button: null,
    click_event: "missions",
    tooltip: "Misje",
    icon: "mission.png",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: null,
  },
  {
    type: "button",
    place: "menu-left",
    order: 3,
    name: "messages",
    gui_notification_type: null,
    gui_unlocks_button: null,
    click_event: "messages",
    tooltip: "Wiadomości",
    icon: "message.png",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: null,
  },
  {
    type: "button",
    place: "menu-left",
    order: 3,
    name: "warehouse",
    gui_notification_type: null,
    gui_unlocks_button: null,
    click_event: "warehouse",
    tooltip: "Magazyn",
    icon: "warehouse.png",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: null,
  },
  // {
  //   type: "button",
  //   place: "menu-left",
  //   order: 1,
  //   name: "city",
  //   gui_notification_type: null,
  //   gui_unlocks_button: "city_map",
  //   click_event: "city",
  //   tooltip: "Miasto",
  //   icon: "city.png",
  //   show_only_active_me: true,
  //   group_name: "go-to",
  //   group_variant: null,
  //   onlyIsActiveMe: true,
  // },
  // {
  //   type: "button",
  //   place: "menu-left",
  //   order: 2,
  //   name: "world",
  //   gui_notification_type: null,
  //   gui_unlocks_button: "treasures_map",
  //   click_event: "treasures",
  //   tooltip: "Łowiska",
  //   icon: "dive.png",
  //   show_only_active_me: true,
  //   group_name: "go-to",
  //   group_variant: null,
  //   onlyIsActiveMe: true,
  // },
  // {
  //   type: "button",
  //   place: "menu-left",
  //   order: 3,
  //   name: "branch",
  //   gui_notification_type: null,
  //   gui_unlocks_button: "branch_map",
  //   click_event: "branch",
  //   tooltip: "Misje zespołowe",
  //   icon: "group.png",
  //   show_only_active_me: true,
  //   group_name: "go-to",
  //   group_variant: null,
  //   onlyIsActiveMe: true,
  // },
  // {
  //   type: "button",
  //   place: "menu-left",
  //   order: 3,
  //   name: "mgm",
  //   gui_notification_type: null,
  //   gui_unlocks_button: "codes",
  //   click_event: "mgm",
  //   tooltip: "Kody",
  //   icon: "codes.png",
  //   show_only_active_me: true,
  //   group_name: null,
  //   group_variant: null,
  //   onlyIsActiveMe: true,
  // },
  {
    type: "button",
    place: "menu-left",
    order: 3,
    name: "qa-daily",
    gui_notification_type: null,
    gui_unlocks_button: "daily_questions",
    click_event: "daily_questions",
    tooltip: "Pytania codzienne",
    icon: "questions.png",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: true,
  },
  {
    type: "button",
    place: "menu-left",
    order: 3,
    name: "qa",
    gui_notification_type: null,
    gui_unlocks_button: "qa",
    click_event: "qa",
    tooltip: "Quizy",
    icon: "quiz.png",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: true,
  },
  {
    type: "button",
    place: "menu-left",
    order: 3,
    name: "reward_codes",
    gui_notification_type: null,
    gui_unlocks_button: "scratch_cards_code",
    click_event: "reward_codes",
    tooltip: "Kody z Nagrodami",
    icon: "codes_gift.png",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: true,
  },
  // {
  //   type: "button",
  //   place: "menu-left",
  //   order: 3,
  //   name: "minigames",
  //   gui_notification_type: null,
  //   gui_unlocks_button: "minigames",
  //   click_event: "minigames",
  //   tooltip: "Minigry",
  //   icon: "games.png",
  //   show_only_active_me: true,
  //   group_name: null,
  //   group_variant: null,
  //   onlyIsActiveMe: true,
  // },
  // {
  //   type: "button",
  //   place: "menu-left",
  //   order: 3,
  //   name: "pills",
  //   gui_notification_type: null,
  //   gui_unlocks_button: "pills",
  //   click_event: "pills",
  //   tooltip: "Wiedza",
  //   icon: "education.png",
  //   show_only_active_me: true,
  //   group_name: null,
  //   group_variant: null,
  //   onlyIsActiveMe: true,
  // },
  // {
  //   type: "button",
  //   place: "menu-left",
  //   order: 3,
  //   name: "mockup-tasks",
  //   gui_notification_type: null,
  //   gui_unlocks_button: "mockup_tasks",
  //   click_event: "mockup_tasks",
  //   tooltip: "Zadania makietowe",
  //   icon: "mockup_tasks.png",
  //   show_only_active_me: true,
  //   group_name: null,
  //   group_variant: null,
  //   onlyIsActiveMe: true,
  // },
  // {
  //   type: "button",
  //   place: "menu-left",
  //   order: 3,
  //   name: "photobooth",
  //   gui_notification_type: null,
  //   gui_unlocks_button: "photobooth",
  //   click_event: "photobooth",
  //   tooltip: "Fotowrzutka",
  //   icon: "photobooth.png",
  //   show_only_active_me: true,
  //   group_name: null,
  //   group_variant: null,
  //   onlyIsActiveMe: true,
  // },
  // {
  //   type: "button",
  //   place: "menu-left",
  //   order: 3,
  //   name: "shop",
  //   gui_notification_type: null,
  //   gui_unlocks_button: "shop",
  //   click_event: "shop",
  //   tooltip: "Market",
  //   icon: "shop.png",
  //   show_only_active_me: true,
  //   group_name: null,
  //   group_variant: null,
  //   onlyIsActiveMe: true,
  // },
  // {
  //   type: "button",
  //   place: "menu-left",
  //   order: 3,
  //   name: "shop_event",
  //   gui_notification_type: null,
  //   gui_unlocks_button: "shop_event",
  //   click_event: "shop_event",
  //   tooltip: "Market eventowy",
  //   icon: "shop_event.png",
  //   show_only_active_me: true,
  //   group_name: null,
  //   group_variant: null,
  //   onlyIsActiveMe: true,
  // },
  // {
  //   type: "button",
  //   place: "menu-left",
  //   order: 3,
  //   name: "chests",
  //   gui_notification_type: null,
  //   gui_unlocks_button: "chests",
  //   click_event: "chests",
  //   tooltip: "Skrzynie",
  //   icon: "chest.png",
  //   show_only_active_me: true,
  //   group_name: null,
  //   group_variant: null,
  //   onlyIsActiveMe: true,
  // },
  {
    type: "button",
    place: "menu-left",
    order: 3,
    name: "exchange",
    gui_notification_type: null,
    gui_unlocks_button: "exchange",
    click_event: "exchange",
    tooltip: "Kantor",
    icon: "exchange.png",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: true,
  },
];
