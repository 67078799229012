import * as moment from "moment";

import { TileTooltipType } from "../../../../constants";
import { TILE_MENU_ATLAS } from "../../../scenes-main/main.constants";
import { CustomWorldMainScene } from "../../../scenes-world/custom/CustomWorld.main.scene";
import { getTileTooltipPositionOffset } from "../../../utils/board.helper";
import { addTextShadow } from "../../../utils/utils";
import { TileTooltipCore } from "../core/TileTooltipCore.class";

export class TileTooltip extends TileTooltipCore {
  afterCreate() {
    if (this.gameService.game.currentScene instanceof CustomWorldMainScene) {
      this.y = this.boardTile.y - 88;
      this.tooltipImage.setOrigin(0.5, 0.5);
    }
  }

  handleTooltipExtensions() {
    switch (this.tooltipType) {
      case TileTooltipType.UPGRADE:
        this.counter = {
          percent: 0,
          intervalRef: null,
          stringTime: "",
          startTime: this.gameService.synchronizeTimeService.getActualLocalTime().getTime(),
          finishTime: moment(this.boardTile.tileData.player_building.built_at),
        };

        const buildTimeTextObject = this.scene.add.text(0, -60, "", this.config.textStyles);
        addTextShadow(buildTimeTextObject);
        buildTimeTextObject.setOrigin(0.5, -1);
        this.setTimer(buildTimeTextObject);
        this.add(buildTimeTextObject);
        break;

      case TileTooltipType.PRODUCTION:
        this.counter = {
          percent: 0,
          intervalRef: null,
          stringTime: "",
          startTime: new Date(this.boardTile.tileData.production.created_at).getTime(),
          finishTime: moment(this.boardTile.tileData.production.finishes_at),
        };
        this.backgroundImage.destroy();
        const container = this.scene.add.container(-65, 0);
        const productionTimeTextObject = this.scene.add.text(35, 0, "", this.config.textStyles);
        addTextShadow(productionTimeTextObject);
        productionTimeTextObject.setOrigin(0, 0.5);
        this.tooltipImage.setPosition(10, 0);
        this.add(container);
        this.setTimer(productionTimeTextObject);

        const icon = this.getProductionIcon();
        const spinnerIcon = this.scene.add.image(-40, 0, TILE_MENU_ATLAS, "production-spinner.png");
        this.setTooltipTexture(icon);
        this.setRotationAnim(spinnerIcon, -1);
        container.add([productionTimeTextObject, this.tooltipImage, spinnerIcon]);

        break;

      case TileTooltipType.PRODUCTION_FINISHED:
      case TileTooltipType.COLLECT:
        let collectValue;
        if (this.boardTile.playerBuildingData["warehouse_income"]) {
          collectValue = this.scene.add.text(
            0,
            -250,
            `${
              this.boardTile.playerBuildingData["warehouse_income"] ||
              this.boardTile.playerBuildingData.auto_production_amount ||
              this.productionFinishValue
            }`,
            this.config.textStyles
          );
        } else if (this.boardTile.playerBuildingData.group === "city_hall") {
          collectValue = this.scene.add.text(
            -100,
            -240,
            `${
              this.boardTile.playerBuildingData["warehouse_income"] ||
              this.boardTile.playerBuildingData.auto_production_amount ||
              this.productionFinishValue
            }`,
            this.config.textStyles
          );
        } else {
          collectValue = this.scene.add.text(
            0,
            -160,
            `${
              this.boardTile.playerBuildingData["warehouse_income"] ||
              this.boardTile.playerBuildingData.auto_production_amount ||
              this.productionFinishValue
            }`,
            this.config.textStyles
          );
        }
        console.log(this.boardTile);
        addTextShadow(collectValue);
        collectValue.setOrigin(0.5, -1);
        this.add(collectValue);
        this.collectValueText = collectValue;
        break;
    }
  }

  setType(type: TileTooltipType) {
    // debugger;
    this.tooltipType = type;
    if (
      (!this.boardTile.isBuildInProgress() && (this.isProductionInProgress() || this.isProductionFinished() || this.isAutoProduction())) ||
      this.boardTile.hasMission()
    ) {
      if (this.boardTile.playerBuildingData.group === "city_hall") {
        this.backgroundImage = this.scene.add.image(-100, -100, TILE_MENU_ATLAS, "take.png");
        this.backgroundImage.setOrigin(0.5);
        this.backgroundImage.setScale(this.config.scaleFactor);
        this.add(this.backgroundImage);
        this.tooltipImage = this.scene.add.sprite(-100, -100, TILE_MENU_ATLAS, "production-spinner.png");
        this.tooltipImage.setScale(this.config.scaleFactor);
        this.tooltipImage.setOrigin(0.5, 0.5);
      } else {
        this.backgroundImage = this.scene.add.image(0, 0, TILE_MENU_ATLAS, "take.png");
        this.backgroundImage.setOrigin(0.5);
        this.backgroundImage.setScale(this.config.scaleFactor);
        this.add(this.backgroundImage);
        this.tooltipImage = this.scene.add.sprite(0, 0, TILE_MENU_ATLAS, "production-spinner.png");
        this.tooltipImage.setScale(this.config.scaleFactor);
        this.tooltipImage.setOrigin(0.5, 0.5);
      }
      let icon = null;

      if (this.boardTile.hasMission()) {
        icon = this.gameService.assetsService.getAsset(`missions/board-main/${this.boardTile.playerBuildingData.player_mission.icon}.png`);
        this.setJumpAnim([this.tooltipImage, this.backgroundImage]);
        if (icon) {
          this.setTooltipTexture(icon.path);
          this.tooltipImage.setOrigin(0.5, 0.58);
        }

        if (this.boardTile.tileData.player_building.player_mission.expires_at) {
          const missionTime = this.scene.add.text(0, -160, "00:00:00", this.config.textStyles);
          addTextShadow(missionTime);
          missionTime.setOrigin(0.5, -1);

          this.counter = {
            percent: 0,
            intervalRef: null,
            stringTime: "",
            startTime: this.gameService.synchronizeTimeService.getActualLocalTime().getTime(),
            finishTime: moment(this.boardTile.tileData.player_building.player_mission.expires_at),
          };
          this.setTimer(missionTime);
          this.add(missionTime);
        }
      } else if (this.isProductionFinished()) {
        const currencyPrize = this.boardTile.tileData.production.currency_prizes[0];
        const productPrize = this.boardTile.tileData.production.product_prizes[0] as any;

        this.productionFinishValue = currencyPrize ? currencyPrize.amount : productPrize.amount;

        let iconKey;
        if (currencyPrize) {
          const currency = this.gameService.currencyService.getCurrencyDefinition(currencyPrize);
          iconKey = currency.key;
          icon = currency.iconUrlBig;
        } else if (productPrize) {
          iconKey = productPrize.icon + "-small";
          const product = this.gameService.productionService.productsService.getProduct(productPrize);
          icon = product.iconUrl;
        }

        this.setTooltipTexture(icon, iconKey);
        this.setJumpAnim([this.tooltipImage, this.backgroundImage]);
      } else if (this.isAutoProduction()) {
        const currencyPrize = this.boardTile.tileData.player_building.automatic_currency;
        const productPrize = this.boardTile.tileData.player_building.automatic_product;
        let iconKey;
        if (currencyPrize) {
          const currency = this.gameService.currencyService.getCurrencyDefinition(currencyPrize);
          iconKey = currency.key;
          icon = currency.iconUrlBig;
        } else if (productPrize) {
          iconKey = productPrize.icon + "-small";
          const product = this.gameService.productionService.productsService.getProduct(productPrize);
          icon = product.iconUrl;
        }
        this.setTooltipTexture(icon, iconKey);
        if (this.boardTile.playerBuildingData.group === "city_hall") {
          this.setJumpAnimCustom([this.tooltipImage, this.backgroundImage], -80);
        } else {
          this.setJumpAnim([this.tooltipImage, this.backgroundImage]);
        }
      }
    } else {
      /**
       * Refactored to allow collect auto production while building build is in progress.
       * This change is made just after another change, that checked if tile should be update silent (without re-rendering),
       * so it's a little mess and redundant now. @todo: need to refactor/rewrite all Tooltip logic. Maybe allow multiple tooltips?
       */
      let icon = null;
      if (this.isAutoProduction() && this.boardTile.playerBuildingData.auto_production_amount > 0) {
        const bg = this.scene.add.image(0, 0, TILE_MENU_ATLAS, "take.png");
        bg.setOrigin(0.5);
        bg.setScale(this.config.scaleFactor);
        this.add(bg);
        this.tooltipImage = this.scene.add.sprite(0, 0, TILE_MENU_ATLAS, "production-spiner.png");
        this.tooltipImage.setScale(this.config.scaleFactor);
        this.tooltipImage.setOrigin(0.5, 0.5);

        const currencyPrize = this.boardTile.tileData.player_building.automatic_currency;
        const productPrize = this.boardTile.tileData.player_building.automatic_product;
        if (currencyPrize) {
          const currency = this.gameService.currencyService.getCurrencyDefinition(currencyPrize);
          icon = currency.iconUrlBig;
        } else if (productPrize) {
          const product = this.gameService.productionService.productsService.getProduct(productPrize);
          icon = product.iconUrl;
        }
        this.type = TileTooltipType.COLLECT;
        this.setTooltipTexture(icon);
        this.setJumpAnim([this.tooltipImage, bg]);
      } else {
        this.tooltipImage = this.scene.add.sprite(0, -110, TILE_MENU_ATLAS, `tooltip_${type}.png`);
        this.tooltipImage.setScale(this.config.scaleFactor);
        this.setLevitationAnimCustom(this.tooltipImage, -90);
      }
    }

    this.add(this.tooltipImage);

    const tooltipOffset = getTileTooltipPositionOffset(this.boardTile.tileData.tile_type, this.boardTile.playerBuildingData.icon);

    this.x = this.boardTile.x + tooltipOffset.x;
    this.y = this.boardTile.y + tooltipOffset.y;

    this.handleTooltipExtensions();
    this.afterCreate();
  }

  setLevitationAnimCustom(target: Phaser.GameObjects.GameObject, high: number) {
    this.scene.add.tween({
      targets: [target],
      repeat: -1,
      yoyo: true,
      duration: 400,
      y: high,
      ease: Phaser.Math.Easing.Quadratic.InOut,
    });
  }
  setJumpAnimCustom(target: Phaser.GameObjects.GameObject[], high: number) {
    this.scene.add.tween({
      targets: target,
      repeat: -1,
      yoyo: true,
      duration: 400,
      y: high,
      ease: Phaser.Math.Easing.Quadratic.Out,
    });
  }
}
