import { BOARD_TILE_HEIGHT, GAME_EVENTS, PHASER_CAMERA_ZOOM } from "../../../constants";
import { CenterMap } from "../../interfaces/shared";
import { calculateMinZoomValue } from "../../utils/game.helper";
import { BASIC_BOOT_SCENE } from "../basic.constants";
import { BasicMainScene } from "../core/Basic.main.scene";

export class CustomBasicMainScene extends BasicMainScene {
  displaySceneSize = {
    width: 0,
    height: 0,
  };

  create() {
    super.create();
    if (this.sceneConfig.other && this.sceneConfig.other.sceneZoom) {
    }
    this.displaySceneSize =
      this.sceneConfig.other && this.sceneConfig.other.sceneSize
        ? {
            width: this.sceneConfig.other.sceneSize.width,
            height: this.sceneConfig.other.sceneSize.height,
          }
        : {
            width: this.sceneConfig.backgroundImages[0].displayWidth,
            height: this.sceneConfig.backgroundImages[0].displayHeight,
          };

    this.gameService.globalService.globalEvents.emit({
      name: "SCENE_CONFIG_CHANGE",
      value: this.sceneConfig,
    });
  }

  setCamera() {
    if (this.sceneConfig.other && this.sceneConfig.other.sceneZoom) {
      // this.setCameraMaxZoomOut();
      // this.setCameraCentered();
      this.setCameraCustom();
      this.setCameraHalfMaxZoomOut();
      setTimeout(() => {
        this.phaserEvents.emit(PHASER_CAMERA_ZOOM, this.cameras.main.zoom);
      }, 50);
    } else {
      super.setCamera();
    }
  }

  setCameraMaxZoomOut() {
    const screenRatio = Math.max(
      this.cameras.main.width / this.cameras.main.getBounds().width,
      this.cameras.main.height / this.cameras.main.getBounds().height
    );
    this.cameras.main.setZoom(1 * screenRatio);
  }

  setCameraHalfMaxZoomOut() {
    const screenRatio = Math.max(
      this.cameras.main.width / this.cameras.main.getBounds().width,
      this.cameras.main.height / this.cameras.main.getBounds().height
    );
    this.cameras.main.setZoom(1 * screenRatio * 2);
  }
  setCameraCentered() {
    this.cameras.main.centerOn(this.cameras.main.getBounds().centerX, this.cameras.main.getBounds().centerY);
  }

  setCameraCustom() {
    this.cameras.main.centerOn(this.cameras.main.getBounds().centerX + 2200, this.cameras.main.getBounds().centerY + 400);
  }

  resize() {
    super.resize();
  }

  focusOn(centerOn: CenterMap) {
    const targetTile = this.board.boardTiles.find(tile => tile.tileData.tile_id === centerOn.tile_id);

    if (!targetTile && this.gameService.lastPlayerIslandId !== centerOn.player_island_id) {
      this.gameService.centerOn = centerOn;
      this.gameService.prepareGameData(this.gameService.playerService.getActivePlayerId(), centerOn.player_island_id);
      this.gameService.globalService.globalEvents.emit({
        name: GAME_EVENTS.START_SCENE,
        value: BASIC_BOOT_SCENE,
        data: {
          sceneConfigKey: centerOn.config_name,
          playerIslandId: centerOn.player_island_id,
        },
      });
      return;
    }

    this.add.tween({
      targets: this.cameras.main,
      duration: 600,
      ease: "Power2",
      scrollX: targetTile.x - this.cameras.main.width / 2,
      scrollY: targetTile.y - this.cameras.main.height / 2 - BOARD_TILE_HEIGHT / 2,
      zoom: this.sceneConfig.other.sceneZoom ? this.sceneConfig.other.sceneZoom.min : calculateMinZoomValue(this.cameras.main),
      onUpdate: () => this.phaserEvents.emit(PHASER_CAMERA_ZOOM, this.cameras.main.zoom),
    });
    this.gameService.centerOn = null;
  }
}
