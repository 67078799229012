import { Injectable } from "@angular/core";
import { ApiCoreGameRanksService } from "../../api/custom/services/api-game-ranks.service";
import { map } from "rxjs/operators";
import { RankGame } from "../../interfaces/custom/rank-game.interface";
import { PlayerService } from "../../../../../player/providers/player.service";
import { CardTask } from "../../../business/interfaces/custom/card-task.interface";

@Injectable({
  providedIn: "root",
})
export class BusinessRanksService {
  bussinesRankList: RankGame[] = [];
  constructor(
    private apiCoreGameRanksService: ApiCoreGameRanksService,
    private playerService: PlayerService
  ) {}

  fetchBussinesRanks() {
    this.apiCoreGameRanksService
      .getGameRanks({ location: 2 })
      .pipe(map(this.filterRanksByTitleId.bind(this)), map(this.filterRanksByBranchId.bind(this)))
      .subscribe(
        (resp: RankGame[]) => {
          this.bussinesRankList = resp;
          // this.setVisibleRanks();
        },
        errResp => {
          this.bussinesRankList = [];
        }
      );
    // this.useMockedData();
  }

  filterRanksByTitleId(ranks: RankGame[]) {
    ranks = ranks.filter(rank => {
      const parameters = rank?.parameters?.visible_on_title_ids;
      let isVisible = false;
      if (!parameters) return true;
      parameters.forEach(param => {
        if (param === this.playerService.player.title_id.id) {
          isVisible = true;
        }
      });
      return isVisible;
    });
    return ranks;
  }
  filterRanksByBranchId(ranks: RankGame[]) {
    ranks = ranks.filter(rank => {
      const parameters = rank?.parameters?.visible_on_branch_ids;
      let isVisible = false;
      if (!parameters) return true;
      parameters.forEach(param => {
        if (param === this.playerService.player.branch_id) {
          isVisible = true;
        }
      });
      return isVisible;
    });
    return ranks;
  }

  isRankInBusinessRank(card: CardTask): CardTask {
    let cardEdited =card;
    this.bussinesRankList.forEach(rank => {
      const parameters = rank?.parameters?.business_task_id;
      if (!parameters) return;
      parameters.forEach(param => {
        if (param === card.selected.task_id) {
          cardEdited.selected.rank_edition_id = rank.rank_edition_id;
          cardEdited.selected.rank_is_visible = true;
        }
      });
    });
    return cardEdited;
  }
}
