<div class="card-container">
  <div
    *ngIf="cardTask"
    class="card"
    [class.choose]="cardTask.other_tasks?.length"
    [ngClass]="cardTask.selected && cardTask.selected.taskClasses"
    [class.card-a]="cardTask.card_type === 'daily'"
    (click)="actionCard()">
    <ng-container *ngIf="cardTask.selected as selectedCard">
      <ng-container
        [ngTemplateOutlet]="titleTpl"
        [ngTemplateOutletContext]="{ taskDefinition: selectedCard.task_definition }"></ng-container>

      <ng-container [ngTemplateOutlet]="cardContentDefaultTpl" [ngTemplateOutletContext]="{ selectedCard: selectedCard }"></ng-container>
    </ng-container>

    <ng-container *ngIf="!cardTask.selected && cardTask.other_tasks?.length" [ngTemplateOutlet]="chooseTpl"></ng-container>
  </div>
</div>

<!-- TITLE TPL -->
<ng-template #titleTpl let-taskDefinition="taskDefinition">
  <!-- TITLE -->
  <div class="title" [style.background-color]="taskDefinition.card_background_color && '#' + taskDefinition.card_background_color">
    <ng-container *ngIf="taskDefinition.partner_logo as partner_logo; else titleTpl">
      <img [src]="partner_logo | asset: 'vendors'" />
    </ng-container>
    <ng-template #titleTpl>
      {{ taskDefinition.name }}
    </ng-template>
  </div>
</ng-template>

<!-- PARAMETRIZE TPL -->
<ng-template #parametrizeTpl>
  <div class="segment">
    <div class="info">Zadanie w trakcie parametryzacji</div>
  </div>
</ng-template>

<!-- CHOOSE TPL -->
<ng-template #chooseTpl>
  <div class="card-content">
    <div class="segment">
      <ow-object-svg [svgObject]="assetsService.assetsData['/ui/logo_mono.svg']"></ow-object-svg>

      <div class="info">Wybierz swoje zadanie</div>
    </div>
  </div>
</ng-template>

<!-- CARD CONTENT DEFAULT TPL -->
<ng-template #cardContentDefaultTpl let-selectedCard="selectedCard">
  <div class="card-content">
    <div class="segment">
      <div class="prizes">
        <div class="prize">
          <ng-container *ngIf="selectedCard.is_cleared">
            <ng-container *ngIf="selectedCard.prizes_paid.length; else emptyPrizeSpaceTpl">
              <m-ui-currency
                *ngFor="let currency of selectedCard.prizes_paid"
                [item]="currency"
                [lack]="false"
                [stockView]="STOCK_VIEW.K"
                [customIconSize]="'small'"
                [customClassStock]="'business-card'"></m-ui-currency>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!selectedCard.is_cleared">
            <ng-container *ngIf="selectedCard.prizes_reference.length; else emptyPrizeSpaceTpl">
              <m-ui-currency
                *ngFor="let currency of selectedCard.prizes_reference"
                [item]="currency"
                [lack]="false"
                [stockView]="STOCK_VIEW.K"
                [customIconSize]="'small'"
                [customClassStock]="'business-card'"></m-ui-currency>
            </ng-container>
          </ng-container>

          <ng-template #emptyPrizeSpaceTpl>
            <m-ui-currency
              [style.opacity]="0"
              [item]="{ currency_id: 1 }"
              [lack]="false"
              [stockView]="STOCK_VIEW.K"
              [customIconSize]="'small'"
              [customClassStock]="'business-card'"></m-ui-currency>
          </ng-template>
        </div>
      </div>

      <div class="progress-block" *ngIf="selectedCard.progress">
        <!-- PROGRESSBAR -->
        <div class="progress-container">
          <!-- VALUE -->
          <!--          <span class="value">-->
          <!--                {{ (selectedCard.progress.label !== null ? selectedCard.progress.label : selectedCard.progress.valuePercentage) | replace:{'.': ','} }}-->
          <!--              </span>-->
          <div
            class="fill custom-color"
            [ngClass]="selectedCard.progress.classes"
            [style.width]="selectedCard.progress.valuePercentage"></div>
        </div>
        <span class="value">
          <!--                {{ (selectedCard.progress.label !== null ? selectedCard.progress.label : selectedCard.progress.valuePercentage) | replace:{'.': ','} }}-->
          {{ selectedCard.progress.valuePercentage }}
        </span>
      </div>

      <div *ngIf="selectedCard.is_cleared" class="absolute-bottom">Rozliczone</div>
    </div>

    <ng-container [ngTemplateOutlet]="parametrizeTpl"></ng-container>
  </div>
</ng-template>

<!-- CARD CONTENT DAILY TPL -->
<ng-template #cardContentDailyTpl>
  <div class="card-content">
    <div class="segment">
      <div class="prizes">
        <div class="prize">
          <ng-container *ngIf="cardTask.dailyHelper.sumResourceScopes as sumResourceScopes">
            <m-ui-currency
              *ngFor="let currency of sumResourceScopes.currencies"
              [item]="currency"
              [lack]="false"
              [stockView]="STOCK_VIEW.K"
              [customIconSize]="'small'"
              [customClassStock]="'business-card'"></m-ui-currency>
          </ng-container>

          <ng-container *ngIf="cardTask.dailyHelper.baseScope as baseScope">
            <m-ui-currency
              *ngFor="let currency of baseScope.currency_prizes"
              [item]="currency"
              [lack]="false"
              [stockView]="STOCK_VIEW.K"
              [customIconSize]="'small'"
              [customClassStock]="'business-card'"></m-ui-currency>
          </ng-container>
        </div>
      </div>

      <div class="info daily" *ngIf="cardTask.dailyHelper.lastCalculation as lastCalculation">
        Rozliczony dzień:<br />
        {{ lastCalculation.date }}
      </div>

      <div class="info daily" *ngIf="cardTask.dailyHelper.nextCalculation as nextCalculation">
        Kolejne rozliczenie:<br />
        {{ nextCalculation.date }}
      </div>
    </div>
  </div>
</ng-template>

<!-- CARD CONTENT RANKING DEFAULT TPL -->
<ng-template #cardContentRankingDefaultTpl>
  <div class="card-content">
    <div class="segment">
      <div class="prizes">
        <div class="prize">
          <m-ui-currency
            *ngFor="let currency of [{ currency_id: 1, amount: 15000 }]"
            [item]="currency"
            [lack]="false"
            [stockView]="STOCK_VIEW.K"
            [customIconSize]="'small'"
            [customClassStock]="'business-card'"></m-ui-currency>
        </div>
      </div>
    </div>
  </div>
</ng-template>
