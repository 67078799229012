import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { select } from "@ngrx/store";
import * as R from "ramda";
import { map } from "rxjs/operators";

// import { CUSTOM_EVENTS } from "../../../../../../../../core/consts/custom/events";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { GlobalService } from "../../../../../../../../core/providers/global.service";
// import { RegisterCustomFirebaseTokenService } from "../../../../../../../../core/services/custom/register-custom-firebase-token.service";
import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";
// import { selectCurrentScene } from "../../../../../../../../store/game/selectors";
import { PlayerSelectors } from "../../../../../../../../store/player";
// import { DISCOVER_MAIN_SCENE } from "../../../../../../game-engine/scenes-discover/discover.constants";
import { MappedMenuButtonConfig, MenuButtonConfig } from "../../../../../../interfaces/menu-buttonts-config.interface";
import { BoardService } from "../../../../../../services/board.service";
import { GameService } from "../../../../../../services/game.service";
import { MenuButtonsConfigService } from "../../../../../../services/menu-buttons-config.service";
// import { QuestionListComponent } from "../../../../../business/basic/custom/dialogs/question-list/question-list.component";
// import { EVENT_DIALOGS_NAMES_CHESTS_CUSTOM } from "../../../../../chests/consts/event-dialogs/event-names.const";
import { EVENT_DIALOGS_NAMES_CURRENCY_EXCHANGE } from "../../../../../currency-exchange/consts/core/event-dialogs/event-names.const";
// import { EVENT_DIALOGS_NAMES_EVENT_SHOP } from "../../../../../event-shop/consts/custom/event-dialogs/event-names.const";
import { EVENT_DIALOGS_NAMES_MESSAGE } from "../../../../../message/consts/core/event-dialogs/event-names.const";
import { MESSAGE_TYPE_ID } from "../../../../../message/consts/core/message-type.const";
// import { EVENT_DIALOGS_NAMES_MGM_CUSTOM } from "../../../../../mgm/consts/custom/event-dialogs/event-names.const";
// import { MockUpTaskListComponent } from "../../../../../mock-up-task/base/custom/dialogs/mock-up-task-list/mock-up-task-list.component";
import { AbstractHudMenuComponent } from "../../../../abstract/core/abstract-hud-menu.component";
import { HudMenuMoreComponent } from "../../dialogs/hud-menu-more/hud-menu-more.component";
import {
  QuestionListComponent
} from "../../../../../business/basic/custom/dialogs/question-list/question-list.component";
import { RewardCodesComponent } from "../../../../../business/basic/custom/dialogs/reward-codes/reward-codes.component";
// import { HudParameters2Component } from "../hud-parameters/hud-parameters.component";
// import { ChangeMapService } from "../../../../services/custom/change-map.service";
// import { HudMenuMoreComponent } from "../../dialogs/hud-menu-more/hud-menu-more.component";
// import { MinigamesComponent } from '../../../../../../../minigames/components/minigames/minigames.component';

@Component({
  selector: "app-custom-hud-menu",
  templateUrl: "./hud-menu.component.html",
})
export class HudMenuComponent extends AbstractHudMenuComponent implements OnInit {
  @OwInject(MenuButtonsConfigService) menuButtonsConfigService: MenuButtonsConfigService;
  @OwInject(GlobalService) globalService: GlobalService;
  @OwInject(GameService) gameService: GameService;
  @OwInject(BoardService) boardService: BoardService;
  @OwInject(Router) router: Router;
  // @OwInject(ChangeMapService) changeMapService: ChangeMapService;
  // @OwInject(RegisterCustomFirebaseTokenService) registerCustomFirebaseTokenService: RegisterCustomFirebaseTokenService;
  @Input() isDialog: boolean;

  menuButtons: MappedMenuButtonConfig[] = [];
  snapShotButtons: MappedMenuButtonConfig[] = [];
  otherButtonsIsActive = false;
  groups = [];

  sceneKey: string;

  ngOnInit() {
    // this.registerCustomFirebaseTokenService.registerFirebase();
    this.subscribePlayer();
    // this.subscribeSceneState();
  }

  subscribePlayer() {
    this.subs.player = this.store.pipe(select(PlayerSelectors.selectPlayer)).subscribe(() => {
      this.afterSubscribePlayer();
    });
  }

  setMenu() {
    let buttons = this.menuButtonsConfigService.getButtonsMenuConfig({
      gui_unlocks: this.playerService.player.gui_unlocks,
      place: this.getMenuPlace(),
      isDialog: this.isDialog,
    });

    buttons = buttons.filter(button => {
      return button.onlyIsActiveMe ? this.playerService.isActiveMe : true;
    });

    if (R.equals(this.snapShotButtons, buttons)) {
      return;
    }
    this.snapShotButtons = buttons;
    this.menuButtons = R.clone(this.snapShotButtons);
    this.setNotification();
    this.otherButtonsIsActive = this.menuButtons.length > 3 && !this.isDialog;
  }

  getMenuPlace() {
    const menuPlace = "menu-left";

    return menuPlace;
  }

  setNotification() {
    this.menuButtons.forEach(button => {
      switch (button.name) {
        case "missions":
          button["customNotification"] = {
            type: "async",
            observable: this.hasMissionsToCollect,
          };
          break;

        case "messages":
          button["customNotification"] = {
            type: "async",
            observable: this.hasNewMessagesToRead,
          };
          break;

        case "warehouse":
          button["customNotification"] = {
            type: "async",
            observable: this.newProductsInStorage.pipe(map(arr => arr.length)),
          };
          break;
      }
    });
  }

  afterSubscribePlayer() {
    this.setMenu();
  }

  handleMenuButtonEvent(button: MenuButtonConfig) {
    if (button.type === "group") {
      this.toggleGroupButtons(button);
    } else if (button.type === "button") {
      switch (button.click_event) {
        case "messages":
          this.openMessages();
          break;

        case "missions":
          this.openMissions();
          break;

        case "warehouse":
          this.openWarehouse();
          break;

        // case "mgm":
        //   this.openMgmInviter();
        //   break;

        case "qa":
          // EC 2 only | no QA -> always pills #20257
          this.openMessagesQa();
          break;

        // case "pills":
        //   this.openMessagesQa();
        //   break;

        // case "question_list":
        //   this.openQuestionList();
        //   break;

        // case "treasures":
        //   this.changeMapService.goToTreasure();
        //   break;

        // case "city":
        //   this.changeMapService.goToCity();
        //   break;

        case "daily_questions":
          this.dialogService.open(QuestionListComponent);
          break;

        // case "minigames":
        //   this.openMinigames();
        //   break;

        case "photobooth":
          this.openPhotoGallery();
          break;

        case "shop":
          this.openShop();
          break;

        // case "shop_event":
        //   this.openEventShopList();
        //   break;
        //
        // case "mockup_tasks":
        //   this.openMockUpTasks();
        //   break;
        //
        // case "chests":
        //   this.openChests();
        //   break;

        case "exchange":
          this.openExchange();
          break;

        case "reward_codes":
          this.dialogService.open(RewardCodesComponent);
          break;

        // case "branch":
        //   this.changeMapService.goToBranch();
        //   break;
      }
    }
  }

  // private toggleGroupButtons(button: MappedMenuButtonConfig) {
  //   button.group_buttons.forEach(groupBtn => (groupBtn.isVisible = !groupBtn.isVisible));
  // }

  // subscribeSceneState() {
  //   this.subs.scene = this.store
  //     .select(selectCurrentScene)
  //     .pipe(filter(Boolean))
  //     .subscribe((currentScene: string) => {
  //       this.sceneKey = currentScene;
  //
  //       const sceneOtherConfig = R.path(["sceneConfig", "other"], this.gameService.game.currentScene) as any;
  //       if (sceneOtherConfig?.isPath) {
  //         document.body.classList.add("path-scene");
  //       } else {
  //         document.body.classList.remove("path-scene");
  //       }
  //
  //       if (sceneOtherConfig?.isBranchMap) {
  //         document.body.classList.add("branch-scene");
  //       } else {
  //         document.body.classList.remove("branch-scene");
  //       }
  //
  //       this.handlerLogicHud();
  //     });
  // }

  // handlerLogicHud() {
  //   switch (this.sceneKey) {
  //     case DISCOVER_MAIN_SCENE:
  //       this.globalService.globalEvents.emit({ name: CUSTOM_EVENTS.GUI.BUSINESS.HIDE });
  //       break;
  //
  //     default:
  //       this.globalService.globalEvents.emit({ name: CUSTOM_EVENTS.GUI.BUSINESS.SHOW });
  //   }
  // }

  // openMgmInviter() {
  //   this.eventEmitterDialogsService.emitter.emit({
  //     name: EVENT_DIALOGS_NAMES_MGM_CUSTOM.INVITER,
  //   });
  // }

  openMessagesQa() {
    const data = {
      type: MESSAGE_TYPE_ID.QA,
      title: " ",
    };

    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_MESSAGE.MESSAGES,
      config: {
        data,
      },
    });
  }

  // openQuestionList() {
  //   this.dialogService.open(QuestionListComponent);
  // }

  async openHudMenuMore() {
    this.dialogService.open(HudMenuMoreComponent);
  }

  openPhotoGallery() {
    this.router.navigate(["/auth/gallery"]);
  }

  openShop() {
    this.router.navigate(["/auth/shop"]);
  }

  // openMockUpTasks() {
  //   this.dialogService.open(MockUpTaskListComponent);
  // }

  // openEventShopList() {
  //   this.eventEmitterDialogsService.emitter.emit({
  //     name: EVENT_DIALOGS_NAMES_EVENT_SHOP.LIST,
  //   });
  // }

  // openChests() {
  //   this.eventEmitterDialogsService.emitter.emit({
  //     name: EVENT_DIALOGS_NAMES_CHESTS_CUSTOM.CHESTS,
  //   });
  // }

  openExchange() {
    this.eventEmitterDialogsService.emitter.emit({
      name: EVENT_DIALOGS_NAMES_CURRENCY_EXCHANGE.CURRENCY_EXCHANGE,
    });
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }

  // private openMinigames() {
  //   this.dialogService.open(MinigamesComponent);
  // }
}
