import { Injectable } from "@angular/core";

import { GlobalService } from "../../../../../../../core/providers/global.service";
import { ChangeMapService } from "../core/change-map.service";

@Injectable({
  providedIn: "root",
})
export class ChangeMapCustomService extends ChangeMapService {
  constructor(public globalService: GlobalService) {
    super(globalService);
  }
}
