import { Routes } from "@angular/router";

import { LoginComponent } from "../../base/core/components/login/login.component";
import {RegistrationConfirmAComponent} from '../../base/core/components/registration-confirm/variant-a/registration-confirm-a.component';
import {CustomLoginComponent} from '../../base/custom/components/login/login.component';

export const CUSTOM_ROUTING_AUTH_CHILDREN: Routes = [
  {
    path: "",
    component: CustomLoginComponent,
  },
  {
    path: 'invitation/:token', component: RegistrationConfirmAComponent,
  },
  {
    path: 'registration/confirm/:token', redirectTo: 'invitation/:token',
  },
];
