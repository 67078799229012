<div class="global-dialog ow-dialog window-a primary no-transparent">
  <loading></loading>
  <div class="top-bar base">
    <div class="title">{{data.name}}</div>
  </div>
  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BAR -->
  <m-ui-back-button *ngIf="data.showBackButton" buttonSize="small"></m-ui-back-button>

  <div class="container">

<!--      Rankingi będą wkrótce widoczne-->

    <ow-perfect-scrollbar class="ps-bottom-border" [scrollGradient]="rankList.length">
      <ng-container *ngIf="rankList.length; then fetchedData; else notfetchedData"></ng-container>
      <ng-template #fetchedData>
        <table>
          <tbody>
            <tr *ngFor="let rank of rankList">
              <td class="ranking-name-row">
                <p>
                  {{ rank.name }}
                </p>
              </td>
              <td>
                <span class="status-rank" [ngClass]="rank.cleared ? 'is-cleared' : 'is-active'">
                  {{ rank.cleared ? "Zamknięty" : "Aktywny" }}
                </span>
              </td>
              <td></td>
              <td>
                <div class="buttons-container">
                  <button *ngIf="rank.description" (click)="openRankDescription(rank)" class="square small primary">
                    <i class="fas fa-info"></i>
                  </button>

                  <button (click)="openRankDetails(rank)" class="square small primary">
                    <i class="fas fa-search"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-template>
      <ng-template #notfetchedData>
        <ng-container *ngIf="!isLoading">
          <div class="wrapper-no-results">
            <p class="no-results">Brak wyników</p>
          </div>
        </ng-container>
      </ng-template>
    </ow-perfect-scrollbar>
  </div>
</div>
