import { EventDialog } from "../../../../../../../core/interfaces/event-dialog.interface";
import { RankingDetailsGame } from "../../../base/custom/dialogs/ranking-game-details/ranking-details-game.component";
import { RankingListComponent } from "../../../base/custom/dialogs/ranking-list/ranking-list.component";
import { EVENT_DIALOGS_NAMES_RANKINGS_CUSTOM } from "./event-names.const";
import { RankingDetailsWorld } from "../../../base/custom/dialogs/ranking-world-details/ranking-details-world.component";

export const EVENT_DIALOGS_RANKINGS_CUSTOM: EventDialog = {
  [EVENT_DIALOGS_NAMES_RANKINGS_CUSTOM.RANKING_LIST]: RankingListComponent,
  [EVENT_DIALOGS_NAMES_RANKINGS_CUSTOM.RANKING_DETAILS_GAME]: RankingDetailsGame,
  [EVENT_DIALOGS_NAMES_RANKINGS_CUSTOM.RANKING_DETAILS_WORLD]: RankingDetailsWorld,
};
