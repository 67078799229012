import { Component, OnDestroy, OnInit } from "@angular/core";

import { orientationModes } from "../../../../../../core/consts/core/orientation-modes.enum";
import { getToken } from "../../../../../../core/utility/token";
import { unsubscribeObject } from "../../../../../../core/utility/unsubscribe-array";
import { AbstractLoginComponent } from "../../../../abstract/abstract-login.component";
import {OwInject} from '../../../../../../core/decorators/ow-inject.decorator';
import {A2hsService} from '../../../../services/custom/a2hs.service';

@Component({
  selector: "app-custom-login",
  templateUrl: "./login.component.html",
})
export class CustomLoginComponent extends AbstractLoginComponent implements OnInit, OnDestroy {
  @OwInject(A2hsService) a2hsService: A2hsService;

  ngOnInit() {
    setTimeout(()=>{
      this.a2hsService.checkPWAisAvailable();
    });
    if (getToken().token) {
      this.guiService.isSplashShow.next(true);
      this.router.navigate(["/auth/player-choose"]);
    } else {
      this.androidService.setNativeAppOrientation(orientationModes.Auto);
      this.subscribeAuthSuccess();
      this.subscribeUserSuccess();
    }
    this.askForNotificationPermission();
  }

  addApplicationToHomeScreen() {
    this.a2hsService.addApplicationToHomeScreen();
  }

  askForNotificationPermission() {
    if (!Notification) {
      return;
    }
    if (Notification.permission === "default") {
      Notification.requestPermission().then(() => {});
    }
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
